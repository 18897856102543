/* Global CSS Variables */
:root {
  --light-background: #{hsla(0, 0%, 100%, 1)};
  --light-background-translucent: #{hsla(0, 0%, 100%, 0.75)};
  --light-background-transparent: #{hsla(0, 0%, 100%, 0.55)};
  --light-background-2: #{hsla(216, 100%, 81%, 0.2)};
  --light-background-3: #{hsl(216, 100%, 99%)};
  --light-background-4: #{hsl(215, 58%, 94%)};
  --mid-background: #{hsla(216, 100%, 37%, 1)};

  --dark-background: #{hsla(205, 48%, 11%, 1)};
  --dark-background-2: #{hsla(205, 48%, 11%, 0.85)};
  --dark-background-translucent: #{hsla(205, 48%, 11%, 0.75)};
  --dark-background-translucent-2: #{hsla(205, 48%, 11%, 0.95)};
  --dark-background-transparent: #{hsla(205, 48%, 11%, 0.55)};

  --overlay: #{hsla(205, 48%, 11%, 0.25)};

  --light-text: #{hsla(0, 0%, 100%, 1)};
  --mid-text: #{hsla(0, 0%, 42%, 1)};
  --mid-text-dark: #{hsla(0, 0%, 35%, 1)};
  --dark-text: #{hsla(205, 48%, 11%, 1)};
  --dark-text-translucent: #{hsla(205, 48%, 11%, 0.7)};

  --light-border: #{hsla(0, 0%, 92%, 1)};
  --dark-border: #{hsla(0, 0%, 18%, 1)};

  --light-icon: #{hsla(216, 36%, 62%, 1)};

  --light-shadow: #{hsla(216, 100%, 36%, 0.1)};
  --light-shadow-2: #{hsla(0, 0%, 20%, 0.075)};
  --light-shadow-3: #{hsla(0, 0%, 20%, 0.1)};
  --mid-shadow: #{hsla(0, 0%, 58%, 1)};

  --input-background: ${hsla(0, 0%, 71%, 0.1)};

  --box-shadow-even: #{0px 0px 9px 7px var(--light-shadow-2)};
  --box-shadow-light: #{2px 7px 9px 7px var(--light-shadow-3)};
  --box-shadow-button: #{2px 3px 12px 0px var(--light-shadow-3)};
  --box-shadow-bottom: #{5px 5px 5px 3px var(--light-shadow-2)};
  --box-shadow-border: var(--light-shadow-3) 0px 0px 10px 2px,
    var(--light-shadow-3) 0px 0px 6px 1px, var(--light-shadow-3) 0px 0px 0px 1px;

  --accent-1: #{hsla(216, 100%, 37%, 1)};
  --accent-1-translucent: #{hsla(216, 100%, 37%, 0.8)};
  --accent-2: #{hsla(0, 0%, 100%, 0.349)};

  --hover-color-light: #{hsla(0, 0%, 100%, 0.349)};
  --hover-opacity: #{0.8};

  --transition-md: #{all 0.3s ease-in-out};

  --border-radius-sm: #{2px};
  --border-radius-md: #{5px};
  --border-radius-lg: #{8px};
  --border-radius-max: #{100%};

  /* Fonts are set in _app.js via next/font */

  --font-size-xxs: #{12px};
  --font-size-xs: #{14px};
  --font-size-sm: #{16px};
  --font-size-sm-2: #{17px};
  --font-size-md-sm: #{19px};
  --font-size-md: #{22px};
  --font-size-md-lg: #{25px};
  --font-size-lg: #{28px};
  --font-size-xl: #{34px};
  --font-size-xxl: #{40px};
  --font-size-xxl-2: #{42px};
  --font-size-xxxl: #{46px};

  --body-font: #{Gilroy};

  --breakpoint-desktop: #{992px};
  --breakpoint-mobile: #{768px};

  --spacing-xxs: #{0.25rem};
  --spacing-xs: #{0.5rem};
  --spacing-sm: #{1rem};
  --spacing-md-sm: #{1.25rem};
  --spacing-md: #{1.5rem};
  --spacing-lg: #{2rem};
  --spacing-xl: #{2.5rem};
  --spacing-xxl: #{3rem};
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  color: var(--dark-text);
  font-weight: 400;
  // font-family: var(--body-font);
  font-family: Gilroy;

  overscroll-behavior-y: contain;

  @media screen and (min-width: 911px) {
    position: unset !important;
    top: unset;
    right: unset;
    left: unset;
  }
}

.variable-container {
  width: 100%;
  max-width: 1180px;
  margin: auto;
  @media (min-width: 320px) {
    max-width: 95%;
  }
  @media (min-width: 420px) {
    max-width: 90%;
  }
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
    padding-right: 12px;
    padding-left: 12px;
    margin-right: auto;
    margin-left: auto;
  }
}

.variable-container-flush {
  width: 100%;
  max-width: 1440px;
  margin: auto;

  @media (min-width: 1441px) {
    padding-right: 12px;
    padding-left: 12px;
  }
}

.hero-container {
  position: relative;
  width: 100%;
  max-width: 1440px;
  max-height: 520px;
  margin: auto;
  overflow-y: hidden;

  @media (min-width: 768px) {
    max-height: 480px;
  }

  @media (min-width: 1400px) {
    max-width: 1440px;
    max-height: 520px;
    margin-right: auto;
    margin-left: auto;
  }
}

/* Universal Typography*/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px;
  color: #1b1b21;
  font-family: var(--heading-font);
}

h1 {
  font-size: var(--font-size-xxl-2);
  font-weight: 600;
}

h2 {
  // font-size: 46px;
  font-size: var(--font-size-xl);
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: var(--font-size-lg);
  }
}

h3 {
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 var(--spacing-xs) 0;
}

h4 {
  font-size: 20px;
  font-weight: 500;
}

ul,
ol {
  font-family: var(--body-font);
  font-weight: 400;
  line-height: 24px;
}

p,
span {
  font-family: var(--body-font);
  font-weight: 400;
  line-height: 150%;
}

.markdown-section ul,
.formatted-list,
.text-container {
  ul,
  li {
    list-style: disc;
  }
}

.text-container {
  position: relative;
}

/* Reset styles for Safari/iOS because Safari is awesome and doesn't cause any problems ever */
input {
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: inherit;

  &::placeholder {
    font-family: var(--body-font);
    font-size: 15px;
    letter-spacing: 1.2;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  select,
  select:focus,
  textarea,
  textarea:focus,
  input,
  input:focus {
    font:
      400 16px/1.5 var(--body-font),
      sans-serif;
    font-size: 16px;
  }
}

/* Global link styles*/
a {
  // color: var(--dark-text);
  text-decoration: none;
}

a:link {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:focus {
  outline: 0 solid;
}

/* Global button styles */
.button {
  background: #0c0908;
  border-radius: 5px;
  min-width: 168px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  color: #fff;
  justify-content: center;
  border: 2px solid transparent;
  transition: 0.3s;

  &:hover {
    border: 2px solid #0e1d28;
    background: #004bbb;
  }
}

button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button:focus {
  outline: 0;
  outline: 5px auto -webkit-focus-ring-color;
}

button:hover {
  background-color: #004bbb !important;
  color: white !important;
  cursor: pointer;
}

/* Global image styles */
img {
  max-width: 100%;
  height: auto;
}

/* Global input/form styles */

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

input {
  &:active,
  &:focus {
    outline: none;
  }
}

.textInput,
.textArea,
.select,
.dateInput,
.select,
.selectOption {
  font-family: var(--body-font), sans-serif;
  font-size: var(--font-size-sm);
}

.textInput {
  height: 40px;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--dark-border);
  background: var(--input-background);
  line-height: 120%;
  padding: 0 var(--spacing-sm);
}

.textArea {
  padding: var(--spacing-sm);
  height: 120px;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--dark-border);
  background: hsla(0, 0%, 71%, 0.1);
  line-height: 120%;
  resize: none;
}

/* Global list styles*/
// ul,
// ul li {
//   list-style: none;
// }

/* Global iframe styles */
iframe {
  border: 0;
}

/* Hubspot form embed styles */
.hubspotFormEmbed {
  div.input,
  input.hs-input {
    margin: 0;
  }
  input.hs-input {
    padding: 0 20px;
  }
  fieldset {
    max-width: 100% !important;
  }
  input:not([type="submit"]) {
    margin: 0;
    background: hsl(220, 100%, 98%);
    border-radius: 5px;
    height: 50px;
    border: 0;
    font-size: 16px;
    line-height: 150%;
    color: #000;
  }
  select {
    min-height: 50px;
    height: 100%;
    padding: 13px 5px 0 10px;
    border: none;
    background: hsl(220, 100%, 98%);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
  }
  textbox {
    background: hsl(220, 100%, 98%);
    border: 0;
    border-radius: 5px;
    padding: 0 20px;
  }
}

/* Global Styles */

.sectionTitle {
  font-size: 46px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
}
.sectionSubtitle {
  font-size: 24px;
  line-height: 132%;
  color: #000000;
  font-weight: 400;
}

.hidden-page-title {
  display: none !important;
}
/* Hubspot popup styles */
.leadinModal {
  .leadinModal-content {
    min-height: 200px;
    max-height: 85dvh !important;
    width: clamp(300px, 580px, 90%) !important;
    border-radius: 2px !important;
    @media (min-height: 500px) {
      overflow: hidden !important;
    }

    @media only screen and (max-height: 750px) {
      overflow: hidden !important;
    }
    .leadinModal-content-wrapper {
      height: 100%;
      .leadin-main-wrapper {
        .leadin-content-body {
          @media (max-width: 768px) {
            padding: 0 !important;
          }
          #leadin-content-form-wrapper {
            min-height: 200px;
            padding: 0 !important;
            h4 {
              color: var(--dark-text);
            }
            .leadin-message-wrapper {
              h4 {
                padding-top: 40px;

                @media (max-width: 768px) {
                  margin: 0;
                }
              }
              .leadinModal-description-3735879 {
                padding-right: 10px;

                p:nth-of-type(2) {
                  padding-right: 10px;
                }
              }
            }
            .leadin-form-wrapper {
              min-width: 230px;
              max-width: 100%;
              margin-left: 0;
              float: right;
              margin-top: 0px;
              margin-right: 30px;

              @media (max-width: 768px) {
                max-width: 80%;
                float: none;
                margin-right: 8% !important;
                margin-left: 38% !important;
              }

              @media (max-width: 480px) {
                max-width: 100%;
                margin: 0 20px !important;
                float: none;
              }

              .leadin-input-wrapper {
                .error-text {
                  position: absolute;
                  width: 125px;
                }
                .input-email {
                  border: 1px solid var(--dark-background);
                }
              }
            }
          }
          .leadin-message-wrapper {
            @media (max-width: 768px) {
              width: 50%;
              margin-left: 38%;
            }
            @media (max-width: 480px) {
              margin-left: 50%;
            }
            .dyno-image {
              width: 50% !important;
              margin-bottom: 0 !important;

              @media (max-width: 768px) {
                display: block;
                position: absolute;
                float: none;
                left: 0;
                right: 0;
                top: 0;
                width: 100% !important;
              }
              .dyno-image-inner {
                img {
                  max-width: 100% !important;
                  margin: 0 !important;

                  @media (max-width: 768px) {
                    max-height: 25dvh !important;
                  }
                }
              }
            }
          }
        }
      }
      .leadinModal-close {
        right: 5px !important;
        top: 5px !important;
        margin: 0.25rem 0.25rem 0 0;
      }
    }
  }
}

/* Global elements */
.spacer {
  width: 100%;
  height: 50px;
  background-color: white;

  @media (max-width: 767px) {
    height: 30px;
  }
}

/* Blaze slider styles */
.wn-hero-slider.blaze-slider {
  --slides-to-show: 1;
}

/* Accordion */
.scroll-margin-top-100 {
  scroll-margin-top: 100px;
  @media (max-width: 1034px) {
    scroll-margin-top: 160px;
  }
}

.leadinModal .leadinModal-content .leadin-close-button {
  text-decoration: none;
  color: var(--light-text);
  width: 100%;
  height: 100%;
  padding: var(--spacing-xs);
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 16px;
  line-height: 130%;
  margin: auto;
  border: 2px solid transparent;
  transition: 0.3s;
  max-width: 200px;
  background: var(--dark-background);
}

/* Hubspot chat embed styles */
div#hubspot-messages-iframe-container {
  bottom: 50px !important;
  max-width: 90%;
  max-height: 75dvh;
  @media (max-width: 768px) {
    max-height: 75dvh;
    max-width: 90%;
    bottom: 50px !important;
    margin-left: var(--spacing-md);
  }
}
.scroll-margin-top-150 {
  scroll-margin-top: 150px;
  @media (max-width: 1034px) {
    scroll-margin-top: 160px;
  }
}

.scroll-margin-top-180 {
  scroll-margin-top: 180px;
  @media (max-width: 1034px) {
    scroll-margin-top: 160px;
  }
}

.experience-scroll-margin-top {
  scroll-margin-top: 150px;
  @media (max-width: 1034px) {
    scroll-margin-top: 160px;
  }
}

/* Animations */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeIn {
  animation: fadeIn 0.4s linear forwards;
}
.fadeOut {
  animation: fadeOut 0.4s linear forwards;
}

@keyframes partialFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.4;
  }
}

@keyframes partialFadeOut {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 0;
  }
}

.fadeInAnimation {
  animation: partialFadeIn 0.4s linear forwards;
}
.fadeOutAnimation {
  animation: partialFadeOut 0.4s linear forwards;
}

/* Hubspot Experience form */
#hs-form-iframe-0 {
  min-height: 300px !important;
}

.logo_img {
  height: 100px;
}

/* Misc to convert to modules */
.regionLink {
  & a {
    color: #182a36 !important;
    font-size: 12px !important;
  }
}

.menuBodyContent {
  & ul li {
    margin-left: 0 !important;
    width: 200px;
  }
  & h5 {
    font-size: 17px;
  }
}

/* Base CSS */

/* Hubspot popup styles */
.submitted-message {
  max-width: max-content;
  margin: auto;
  background: var(--accent-1-translucent);
  padding: 1rem;
  border-radius: 4px;
  color: var(--light-text);
  text-align: center;
  font-size: 24px;
  p {
    margin-bottom: 0;
  }
}

.leadinModal.leadinModal-theme-default.leadinModal-v3 .leadinModal-close {
  top: 5px !important;
  right: 5px !important;
}

/* Hubspot chat embed styles */
div#hubspot-messages-iframe-container {
  bottom: 50px !important;
  max-width: 90%;
  max-height: 75dvh;
  @media (max-width: 768px) {
    max-height: 75dvh !important;
    max-width: 90%;
    bottom: 50px !important;
    margin-left: var(--spacing-md);
  }
}

mapbox-search-listbox {
  [role="listbox"] + div {
    display: none;
  }
}
