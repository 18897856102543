@keyframes htmlRatioComponentNodeInserted {
    from {
        color: currentColor;
    }
    to {
        color: currentColor;
    }
}

*[ratio] {
    -webkit-animation-duration: .001s;
    animation-duration: .001s;
    -webkit-animation-name: htmlRatioComponentNodeInserted;
    animation-name: htmlRatioComponentNodeInserted;
}