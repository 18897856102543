// .cl-modalContent {
.cl-signIn-root {
  .cl-card {
    .cl-footer {
      .cl-footerAction__signIn {
        display: flex;
        flex-direction: column;
        width: 100%;

        .cl-footerActionLink {
          display: block;
          width: 100%;
          padding: 0.625rem 1.25rem;
          border-radius: 0.375rem;
          background-color: black;
          color: white;
          font-size: 0.6875rem;
          font-weight: 600;
          text-align: center;
          text-transform: uppercase;

          &:hover {
            background-color: var(--accent-1);
            text-decoration: none;
          }
        }
      }
    }
  }
}
// }

.cl-signUp-root {
  .cl-card {
    .cl-main {
      /* Remove optional fields */
      .cl-formFieldRow__name,
      .cl-formFieldRow__username {
        display: none;
      }
    }
  }
}

/* Clerk webview */

.wn-is-webview {
  .cl-socialButtonsBlockButton__google,
  .cl-socialButtonsIconButton__google,
  .cl-socialButtonsBlockButton__facebook,
  .cl-socialButtonsIconButton__facebook {
    display: none;
  }

  button:has(.cl-socialButtonsProviderIcon__google),
  button:has(.cl-socialButtonsIconButton__facebook) {
    display: none;
  }
}
